/** @jsx jsx */

import { jsx, Box } from 'theme-ui';
import { graphql } from "gatsby"
import {
  ContentPage,
  ContentPageHeader,
  CompactContentHeader,
  TextOnlyHeader,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { useLocalisedContentViewer, useLocalisedContent } from '../components/featured';
import SEO from '../components/seo';
import { ContentMetadata, ArticleLanguageSelector, ContentMetadataItem } from '../components/content-page';
import { Join } from '../data/list';
import { useTranslation } from 'react-i18next';
import { useTextDirection, TextDirectionProvider } from '../i18n';

const MiscPage: PageRoot<{ node: ContentNode }> = ({
  data: { node },
  pageContext: { locale, locales },
  location: { pathname }
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const topic = useLocalisedContent(node.frontmatter.topic)
  const dir = useTextDirection(locale)

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          title={localisedContent.title}
          description={localisedContent.shortAbstract || localisedContent.abstract}
          imageUrl={node.frontmatter.featuredImage?.publicURL}
        />
        {node.frontmatter.featuredImage ? (
          <ContentPageHeader node={node}>
            <CompactContentHeader {...localisedContent} content={node} />
          </ContentPageHeader>
        ) : (
          <TextOnlyHeader
            standalone
            themeColor={node.fields.themeColor}
            typeName={node.fields.typeName}
            location={node.frontmatter.location}
            topic={topic?.title}
            title={localisedContent.title}
            hasVideo={node.frontmatter.hasVideo}
            locales={locales}
            noChips
          />
        )}

        <ContentPage
          {...localisedContent}
          content={node}
          fontFamily={'body'}
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem
                  label={node.frontmatter.authors.length > 1 ? t(`Authors`) : t(`Author`)}
                >
                  <Join
                    value={node.frontmatter.authors.map(a => a.frontmatter.title)}
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={`${localisedContent.languageName} ${localisedContent.translators?.length > 1 ? t(`Translators`) : t(`Translator`)}`}
                >
                  <Join
                    value={localisedContent.translators?.map(a => a.frontmatter.title)}
                  />
                </ContentMetadataItem>
              )}
            </ContentMetadata>
          } />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query MiscPage($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }
  }
`

export default MiscPage
